import { useTickerData } from '@/hooks/useTickers';
import { TickerLinkQuote } from '@/types/frontend/page';
import cn from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { Fragment, useEffect } from 'react';
import TickerLogo from './TickerLogo';

export function fixLink(router, linkParam: string, msh_id: string) {
  if (!router) return '#';
  if (!linkParam) return '#';
  let link = linkParam;

  if (
    router.pathname.includes('holdings') ||
    router.pathname.includes('[...tab]') ||
    router.pathname.includes('data')
  ) {
    link = '/symbols/[ticker]';
  }
  let fixedLink = link?.replace('[ticker]', msh_id);
  const topic = router?.query?.topic;
  if (topic && typeof topic === 'string') {
    fixedLink = fixedLink.replace('[topic]', topic);
  }
  const period = router?.query?.period?.[0];
  if (period && typeof period === 'string') {
    fixedLink = fixedLink.replace('[...period]', period);
  }
  return fixedLink;
}

interface TickerLinkProps {
  quote?: TickerLinkQuote;
  showText?: boolean;
  newTab?: boolean;
  className?: string;
  onClick?: () => void;
  prefetch?: boolean;
  msh_id?: string;
  small?: boolean;
  large?: boolean;
  flipped?: boolean;
}

const TickerLink = ({
  className = '',
  flipped,
  quote,
  msh_id,
  newTab,
  showText = true,
  onClick,
  small,
  prefetch = false,
  large = false
}: TickerLinkProps) => {
  if (!quote?.msh_id && !msh_id) return null;

  const { tickerData } = useTickerData([quote ? '' : msh_id || '']);

  if (!quote && !msh_id) return null;

  const [didTimeout, setDidTimeout] = React.useState(false);

  const resolvedQuote = quote ? quote : tickerData[msh_id || ''];
  const loading = !(resolvedQuote?.name || resolvedQuote?.symbol);

  const expiration = dayjs(resolvedQuote?.expiration_date);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!resolvedQuote) {
        setDidTimeout(true);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <span className={`cursor-pointer  items-center  ${className}`}>
      <Link
        href={`/symbols/${resolvedQuote?.msh_id}`}
        className={cn(
          'max-w-[200px] flex flex-row h-full shrink min-w-0 items-center ',
          {
            'flex-row-reverse': flipped,
            'flex-row': !flipped,
            'gap-x-3': !small,
            'gap-x-2': small
          }
        )}
      >
        <Fragment>
          <span
            title={`${resolvedQuote?.name} (${resolvedQuote?.msh_id})}`}
            className={cn('rounded grow-0 shrink-0 ', {
              ' w-6 h-6 ': !large && small,
              ' w-8 h-8 md:w-8 md:h-8': !large && !small,
              'w-10 h-10': large
            })}
          >
            {loading && !didTimeout ? (
              <div className="w-full h-full bg-line rounded animate animate-pulse" />
            ) : (
              <TickerLogo quote={resolvedQuote || { msh_id: msh_id }} />
            )}
          </span>

          {showText && (
            <span
              className={cn(
                ' flex flex-col h-full justify-between  min-w-0 shrink  ',
                {
                  ' gap-y-0': !small
                }
              )}
            >
              <span className="  flex-col  text-ellipsis overflow-hidden whitespace-nowrap text-[0.6rem] opacity-70 dark:opacity-80 text-primary font-medium dark:font-normal leading-0">
                {resolvedQuote?.msh_id}
              </span>
              <span
                className={`${`text-primary text-ellipsis text-xs whitespace-nowrap overflow-hidden rounded`} dark:font-medium font-semibold leading-0 ${
                  small ? 'text-xs' : ''
                } `}
              >
                {resolvedQuote?.name || resolvedQuote?.description || ''}
                {resolvedQuote?.market == 'OPTIONS' && (
                  <span>
                    {resolvedQuote.expiration_date &&
                      `${expiration.format('MMM')} ${expiration.format('DD')}`}
                    <span
                      className={`ml-1 ${
                        resolvedQuote.option_type == 'P'
                          ? 'dark:text-[#de0085]'
                          : 'dark:text-[#008ee2]'
                      }`}
                    >
                      {'| '}
                      {resolvedQuote.strike}{' '}
                      {resolvedQuote.option_type == 'P' ? 'PUT' : 'CALL'}
                    </span>
                  </span>
                )}
              </span>
            </span>
          )}
        </Fragment>
      </Link>
    </span>
  );
};
export default TickerLink;
